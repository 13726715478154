namespace eh {

  interface EhVideoPlayer extends Flowplayer, JQuery<HTMLElement> {
    lastScrollPosY: number;
    ehFullScreenCloseHandler: () => void;
  }
  
  export class VideoPlayer {
    
    private static readonly maxWidthForMobile = 1025;
    
    static init($base: JQuery<HTMLElement>): void {
      $('.eh-player', $base).each((_index, element) => {
        const $el = $(element);
        try {
          const player = $el.flowplayer({
            'fullscreen': true,
            'key': '$592552535714410, $727343743838562',
            'logo': '',
            'native_fullscreen': true,
            'share': false,
            'splash': false
          }).data('flowplayer');
          // show the play button again when video has paused
          player.on('pause', (): void => {
            setTimeout(() => {
              $('.fp-ui .fp-play', $(element)).addClass('fp-visible');
            }, 250);
          });
          player.on('resume', (): void => {
            $('.eh-player').each((idx, elem) => {
              if($(elem).data('ignoreOneEvent')) {
                $(elem).data('ignoreOneEvent', false);
                return;
              }
              const fp: Flowplayer = $(elem).data('flowplayer');
              if(fp !== player) {
                fp.pause();
              }
            });
          });
          setTimeout(() => {
            const fp = $el.data('flowplayer');
            if (fp && !fp.ready) {
              const v = $('video', $el).get(0);
              if (v instanceof HTMLMediaElement) {
                v.load();
              }
            }
          }, 50);
          $el.selfOrFind(`.${eh.OVERLAY_EVENTS.EVENT_NODE_CLASS}`).on(OVERLAY_EVENTS.LAYER_STATE_EVENT, (e: JQuery.TriggeredEvent, metrics: OVERLAY_EVENT_PARAMS) => {
            if(metrics.state == OVERLAY_EVENTS.LAYER_STATE_CLOSED) {
              player.pause();
            } 
            if(metrics.state == OVERLAY_EVENTS.LAYER_STATE_OPENED) {
              // player.play(); demanded by testimonial video in overlay
              $el.data('ignoreOneEvent', true);// !== fails in overlay ?!?
              player.resume();
            } 
          });
        }
        catch (e) {
          // error in fp...
        }
      }).on('click', ($event) => {
        const $el = $($event.currentTarget);
        const fp: Flowplayer = $el.data('flowplayer');
        if (fp && fp.paused && !fp.isFullscreen && window.screen.width < VideoPlayer.maxWidthForMobile) {
          const ehFpInstance: EhVideoPlayer = fp as EhVideoPlayer;
          ehFpInstance.lastScrollPosY = eh.ScrollPage.getScrollPosition();
          if (eh.isIOSSafari() && !ehFpInstance.ehFullScreenCloseHandler) {
            ehFpInstance.ehFullScreenCloseHandler = () => eh.ScrollPage.scrollTo(ehFpInstance.lastScrollPosY);
            ehFpInstance.on('fullscreen-exit', ehFpInstance.ehFullScreenCloseHandler);
          }
          fp.fullscreen();
        }
      });
    }
  }
  
}
