namespace eh {

  /** teasers as pure button list - handle COM-2452 ; pc_link-teaser-block--topics-in-focus-home **/
  export class ShortcutController {
    public static SHORTCUT_BAR_WRAPPER_CLASSNAME: string = 'ehel-shortcut-bar';
    public static SHORTCUT_BAR_BUTTONS_CLASSNAME: string = 'ehel-shortcut-bar--buttons';
    public static SHORTCUT_BAR_SHOW_MORE_LESS_CLASSNAME: string = 'ehel-shortcut-bar--show-more-less';
    public static SHORTCUT_BAR_CONTENT_CLASSNAME: string = 'ehel-shortcut-bar--content';
    public static COLS_TO_SHOW: number = 4;

    static init($base: JQuery<HTMLElement>): void {
      $(`.${ShortcutController.SHORTCUT_BAR_WRAPPER_CLASSNAME}`, $base).each((index: number, element: HTMLElement) => {
        new ShortcutController(element);
      });
    }

    private shortcutBarButtons: HTMLElement | null | undefined;
    private shortcutBarShowMoreLess: HTMLElement | null | undefined;
    private shortcutBarContent: HTMLElement | null | undefined;

    private vm: EhShortcutViewModel;

    constructor(
      private readonly base: HTMLElement | null,
    ) {
      this.shortcutBarContent = this.base?.querySelector(`.${ShortcutController.SHORTCUT_BAR_CONTENT_CLASSNAME}`);
      this.shortcutBarButtons = this.shortcutBarContent?.querySelector(`.${ShortcutController.SHORTCUT_BAR_BUTTONS_CLASSNAME}`);
      this.shortcutBarShowMoreLess = this.shortcutBarContent?.querySelector(`.${ShortcutController.SHORTCUT_BAR_SHOW_MORE_LESS_CLASSNAME}`);
      if (!this.shortcutBarContent || !this.shortcutBarButtons || !this.shortcutBarShowMoreLess) {
        return;
      }
      this.vm = new EhShortcutViewModel(
        this.shortcutBarButtons,
        this.shortcutBarShowMoreLess,
        this.shortcutBarContent
      );
    }
  }

  class EhShortcutViewModel {
    constructor(
      private shortcutBarButtons: HTMLElement,
      private shortcutBarShowMoreLess: HTMLElement,
      private shortcutBarContent: HTMLElement) {
      this.init();
    }
    private init(): void {
      eh.Breakpoints.getInstance().registerResizeListener(this.onResize);
    }
    public onResize: () => void = (): void => {
      const buttons = this.shortcutBarButtons.querySelectorAll('a');
      let rows = 0;
      let sumWidth = 0;
      let heightColumns = 0;
      buttons.forEach(button => {
        sumWidth += button.offsetWidth + 12; // Gap 12
        if (sumWidth > (this.shortcutBarButtons.parentElement?.offsetWidth || window.innerWidth)) {
          sumWidth = button.offsetWidth + 12;
          rows++;
          if (!heightColumns && rows > ShortcutController.COLS_TO_SHOW) {
            heightColumns = button.offsetTop;
            this.shortcutBarContent.style.setProperty('--ehel-shortcut-closed-h', `${heightColumns + this.shortcutBarShowMoreLess.offsetHeight}px`);
          }
        } else if (!rows) {
          rows++;
        }
      });
      this.shortcutBarContent.style.setProperty('--ehel-shortcut-h', `${this.shortcutBarButtons.offsetHeight + this.shortcutBarShowMoreLess.offsetHeight + 12}px`);
      if (heightColumns) {
        this.shortcutBarShowMoreLess.style.top = `max(${heightColumns}px,calc(100% - 41px))`;
      }
    };
  }
}