namespace eh {

  export type CoverType = 'local-tw' | 'local-lightbox';

  export class CoverCtrl  {
    public static readonly EventIdPreClose = eh.Overlay.EventIdPreClose;//'ehOverlay:preClose';
    static readonly DATA_KEY__LOCAL_REF_ID = 'localRefId';
    static readonly DATA_KEY__OVERLAY_CONTENT = 'eh.Overlay.content';
  
    private static readonly logger = log.getLogger('eh.CoverCtrl');
    private static instance: CoverCtrl;

    private currentCover:CoverFace | null = null;
    private coverTw:CoverTw;
    private coverLightbox:CoverLightbox;

    public readonly id: string = ('eh-coverctrl-instance');
    public readonly isMobileSafari: boolean;
    public static readonly closeCover = () => {
      CoverCtrl.instance?.closeCurrent();
    };

    static init($base: JQuery<HTMLElement>, isSnippet: boolean): void {
      if(!isSnippet) {
        CoverCtrl.instance = new CoverCtrl($base);
      } else {
        this.registerLinks($base);
      }
    }

    static registerLinks($base: JQuery<HTMLElement>): void {
        CoverCtrl.instance?.registerCoverLinks($base);
    }

    private constructor(private readonly $base: JQuery<HTMLElement>) {
      const userAgent = window.navigator.userAgent;
      this.isMobileSafari = (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) !== null;
      this.coverLightbox = new CoverLightbox(this);
      this.coverTw = new CoverTw(this);
      this.registerCoverLinks(this.$base);
    }
  
    private registerCoverLinks($base: JQuery<HTMLElement>) {
      $('[data-lightbox]:not([data-evict-lightbox=1])', $base).each((_index, el)  => {
        const $link = $(el);
        let type : CoverType = $(el).data('lightbox');
        if(type) {
          $link.on('click', (event) => {
            event.preventDefault();
            if(!$link.hasClass('disabled')) {
              let initData:CoverInitData = this.analyze($link);
              let cover: CoverFace | null = null;
              switch(type) {
                case 'local-lightbox':
                  cover = this.coverLightbox;
                  break;
                case 'local-tw':
                  cover = this.coverTw;
                  break;
                default:
                  return;
              }
              if(cover) {
                Overlay.closeAll();
                CoverCtrl.instance?.open(cover, initData);
              }
            }
          });
        }
      });
      $('.js-overlay-close', $base).on('click', (ev) => {
        if ($(ev.target).selfOrClosest('.eh-overlay--content-body, .eh-overlay--close').length !== 1) {
          return;
        }
        ev.preventDefault();
        this.closeCurrent();
      });
    }

    private open(cover:CoverFace, initData:CoverInitData):void{
      this.closeCurrent();
      this.currentCover = cover;
      cover.show(initData);
    }

    public closeCurrent() {
      this.currentCover?.dispose();
      this.currentCover = null;
    }

    private analyze(initor: JQuery<HTMLElement>): CoverInitData {
      // ref might be: #id-1#id-2 | id-1 | /url | https://url
      const href = initor.attr('href') || '#';
      const title = initor.data('title') || '';
      if (href.search(new RegExp('^(/)|(https?://)')) > -1) {
        return {type: 'url', url: href, id: undefined, additionalId: undefined};
      }
      const parts = href.split('#');
      if (parts.length === 1) {
        return {type: 'id', id: parts[0]};
      }
      return {type: 'id', id: parts[1], additionalId: parts[2], url: undefined};
    }

  }

  class CoverBase {
    protected wrapper: JQuery<HTMLElement>;
    protected content: JQuery<HTMLElement>;
    protected title: JQuery<HTMLElement>;
    protected closer: JQuery<HTMLElement>;
    protected contentCloser: JQuery<HTMLElement> | undefined;
    private isOpen: boolean = false;

    constructor(readonly ctrl:CoverCtrl, classWrapper:string) {
      this.wrapper = $(classWrapper);
      if (ctrl.isMobileSafari) {
        this.wrapper.addClass('safari-mobile');
      }
      this.content = this.wrapper.find('.eh-overlay--content-body');
      this.closer = this.wrapper.find('.eh-overlay--close');
      this.title = this.wrapper.find('.eh-overlay--headline');
    }

    protected beforeOpen(data: CoverInitData) {
      if(data.title) {
        this.title.html(data.title);
      }
      this.contentCloser = $('.js-overlay-close', this.content);
      this.contentCloser.on('click', (ev) => {
        ev.preventDefault();
        this.ctrl.closeCurrent();
      });
      // why? this.wrapper && CoverCtrl.notifyReplaced($(this.wrapper));
      this.closer.on('click', (ev) => {
        ev.preventDefault();
        this.ctrl.closeCurrent();
      });
      if(! this.isOpen) {
        this.isOpen = true;
        ScrollPage.setScrollEnabled(false, 'eh-no-scroll eh-full-height');
        // next leads to bug in VideoPlayer .. COM 2572
        // this.notifyLayerState(this.content, this.isOpen);
      }
      this.wrapper.get().forEach(overlay => {
        overlay.addEventListener('keydown', (e: KeyboardEvent) => {
          if(["Escape"].indexOf(e.code) > -1) {
            this.closer.trigger('click');
          }
        });
      })
      this.wrapper.on('mouseover', (ev) => {
        this.wrapper.trigger('focus');
      });
    }

    protected cleanupContent(deferred?: boolean):void {
      this.title.empty();
      this.closer.off('click');
      if (this.contentCloser) {
        this.contentCloser.off('click');
      }
      if (deferred) {
        setTimeout(() => {
          // allow the Tracking to access the content before it is removed
          this.putBack();
        }, 0);
      } else {
        this.putBack();
      }
      if(this.isOpen) {
        this.isOpen = false;
        ScrollPage.setScrollEnabled(true, 'eh-no-scroll eh-full-height');
        this.notifyLayerState(this.content, this.isOpen);
      }
    }
    
    protected notifyReplaced(el: JQuery<HTMLElement>) {
      const event = jQuery.Event(cs.Snippet.EventIdPostReplace) as cs.SnippetEventPostReplace;
      event.replacedTarget = el;
      $(':root').trigger(event);
    }

    private notifyLayerState(wrap: JQuery<HTMLElement>, isOpen:boolean) {
      // find .ehts-overlay-events
      // send event
      wrap.selfOrFind(`.${OVERLAY_EVENTS.EVENT_NODE_CLASS}`).trigger(OVERLAY_EVENTS.LAYER_STATE_EVENT, {
        'state': isOpen? OVERLAY_EVENTS.LAYER_STATE_OPENED : OVERLAY_EVENTS.LAYER_STATE_CLOSED
      });
    }

    private putBack() {
      const refId = this.wrapper.data(CoverCtrl.DATA_KEY__LOCAL_REF_ID);
      if (refId) {
        this.wrapper.data(CoverCtrl.DATA_KEY__LOCAL_REF_ID, undefined);
        const $originalParent = $('#' + refId);
        if ($originalParent.length === 1) {
          const $contentParentWrapperToTransferBack = this.content.find('.is-wrapper');
          const $contentParentToTransferBack = $contentParentWrapperToTransferBack.length > 0
          ? $contentParentWrapperToTransferBack
          : this.content;
          $originalParent.append($contentParentToTransferBack.children());
        } 
      }
      this.content.empty();
    }
  }

  class CoverTw extends CoverBase implements CoverFace {
    private previousPage: HTMLElement[] = [];
    private jqXHR?: JQuery.jqXHR;

    constructor(readonly ctrl:CoverCtrl) {
      super(ctrl, '.eh-marker-overlay-tw');
    }

    show(initData: CoverInitData) {
      this.dispose(false);
      let cid = initData.additionalId ?? initData.id ?? undefined;
      const innerContent = $('<div class="is-wrapper"></div>');
      if(cid) {
        this.wrapper.data(CoverCtrl.DATA_KEY__LOCAL_REF_ID, cid);
        innerContent.append($('#' + cid).children());
      } else if(initData.url) {
        this.jqXHR = $.ajax({
          'url': initData.url,
          'dataType': 'html',
          'method': 'get'
        });
        this.jqXHR.then(
          (data: any) => {
            innerContent.empty().append($(data).children());
            this.notifyReplaced(innerContent);
          }, 
          (_jqXHR, textStatus, errorThrown) => {
          }
        );
      }
      this.content.data(
        CoverCtrl.DATA_KEY__OVERLAY_CONTENT,
        innerContent.children()
      );
      this.content.append(innerContent);
      this.wrapper.show();
      this.beforeOpen(initData);
      //this.notifyReplaced($(this.wrapper));

      this.content.find('.eh-overlay-back').each((ind, backEl) => {
        backEl.classList.add('ehtw-hidden');
      });
      let page = (initData.type == 'id') ? initData.additionalId ?? '' : '';
      this.content.find('.eh-overlay-page').each((index, el) => {
        el.style.transition = 'none';
        if (el.parentElement) {
          el.parentElement.style.transition = 'none';
        }
        if ((!page && index > 0) || (page && page != el.id)) {
          el.style.left = '100%';
        } else {
          el.style.left = '0%';
          if (el.parentElement) {
            el.style.height = '';
            el.parentElement.style.height = (el.clientHeight + 48) + 'px';
            el.style.height = 'calc(100% - 48px)'
          }
          this.previousPage.push(el);
        }
        setTimeout(() => {
          el.style.transition = '';
          if (el.parentElement) {
            el.parentElement.style.transition = '';
          }
        }, 500);
      });
      // Probar con transform 
      this.content.find('.eh-overlay-back').each((index, el) => {
        el.addEventListener('click', (ev) => {
          ev.preventDefault();
          const last = this.previousPage[this.previousPage.length - 1];
          const previous = this.previousPage[this.previousPage.length - 2];
          this.previousPage.pop();
          if (this.previousPage.length > 1) {
            el.classList.remove('ehtw-hidden');
          } else {
            el.classList.add('ehtw-hidden');
          }
          previous.style.left = '0%';
          if (previous.parentElement) {
            previous.style.height = '';
            previous.parentElement.style.height = (previous.clientHeight + 48) + 'px';
            previous.style.height = 'calc(100% - 48px)'
          }
          last.style.left = '100%';
        });
      });
      this.content.find('.eh-overlay-link-page').each((index, el) => {
        el.addEventListener('click', (ev) => {
          ev.preventDefault();
          const href = el.getAttribute('href');
          this.content.find('.eh-overlay-page').each((index, page) => {
            if (`#${page.id}`=== href ) {
              page.style.left = '0';
              if (page.parentElement) {
                page.style.height = '';
                page.parentElement.style.height = (page.clientHeight + 48) + 'px';
                page.style.height = 'calc(100% - 48px)';
              }
              this.previousPage.push(page);
              this.content.find('.eh-overlay-back').each((ind, backEl) => {
                backEl.classList.remove('ehtw-hidden');
              });
            } else {
              page.style.left = '-100%';
            }
          });
        })
      });
      ScrollPage.setScrollEnabled(false, 'eh-no-scroll eh-full-height');
    }
    dispose(deferred:boolean = true): void {
      this.jqXHR?.abort();
      this.wrapper.hide();
      this.previousPage = [];
      const $el = this.content.data(CoverCtrl.DATA_KEY__OVERLAY_CONTENT);
      $($el).trigger($.Event(Overlay.EventIdPreClose));
      ScrollPage.setScrollEnabled(true, 'eh-no-scroll eh-full-height');
      this.wrapper.find('.eh-scroll-vertical').scrollTop(0);
      this.wrapper.find('.eh-overlay--content-body').scrollTop(0);
      this.cleanupContent(deferred);
    }
  }

  class CoverLightbox extends CoverBase implements CoverFace {

    constructor(readonly ctrl:CoverCtrl) {
      super(ctrl, '.eh-marker-overlay-lightbox');
    }

    show(initData: CoverInitData): void {
      this.dispose(false);
      let cid = initData.id ?? undefined;
      const innerContent = $('<div class="is-wrapper"></div>');
      if(cid) {
        this.wrapper.data(CoverCtrl.DATA_KEY__LOCAL_REF_ID, cid);
        innerContent.append($('#' + cid).children());
      }
      this.content.data(
        CoverCtrl.DATA_KEY__OVERLAY_CONTENT,
        innerContent.children()
      );
      this.content.append(innerContent);
      this.beforeOpen(initData);
      this.wrapper.show();
    }

    dispose(deferred:boolean = true): void {
      this.wrapper.hide();
      const $el = this.content.data(CoverCtrl.DATA_KEY__OVERLAY_CONTENT);
      $($el).trigger($.Event(Overlay.EventIdPreClose));
      ScrollPage.setScrollEnabled(true, 'eh-no-scroll eh-full-height');
      this.cleanupContent(deferred);
    }
  }

  type CoverInitData = {
    type: 'url' | 'id';
    title?: string;
    url?: string;
    id?: string;
    additionalId?: string;
  };

  interface CoverFace {
    show(initData: CoverInitData):void;
    dispose(deferred?:boolean):void;
  }

}

