namespace eh {

  type EventParams = {
    event_name: EventName,
    [key: string]: string | undefined | null;
  };
  
  type DataLayer = {
    [key: string]: string | undefined | null;
  };
  
  type EventName = 'accordion' | 'breadcrumb' | 'checkbox' | 'click_in' | 'content_link' | 'cookie_layer' | 'download'
      | 'download_link_dla' | 'email' | 'filter' | 'filter_link' | 'flex' | 'form' | 'header' | 'header_sticky' | 'image'
      | 'imagemap' | 'imagemap_icon' | 'imagemap_link' | 'login' | 'logo' | 'navi_burger'  | 'navi_meta_level1'
      | 'navi_main_level1' | 'navi_main_level2' | 'navi_main_level3' | 'navi_main_expand'
      | 'navi_teaser' | 'phone' | 'product_action' | 'radio_button' | 're-routing' | 'scroll_depth' | 'search' | 'share' | 'show_all'
      | 'show_alternative' | 'show_drawing' | 'show_more' | 'slide' | 'socialmedia' | 'subscribe' | 'subtab'
      | 'switch_site' | 'tab' | 'teaser' | 'view_cart' | 'info_icon' | 'show_CAD' | 'cta_button' | 'anchor';
  
  type TrackedEvent = JQuery.TriggeredEvent  & {'__csTrackingProcessed'?: boolean};
  
  type OnsiteNavigation = [newHeading: string, siblingHeadings: string[]];
  
  export class Tracking {
    private static readonly logger = log.getLogger('eh.Tracking');
    public static readonly qaLogger = log.getLogger('eh.Tracking.QA');
    
    public static readonly earlyOnsiteNavigations: OnsiteNavigation[] = [];
    private static ScrollTracking :ScrollDepthTracker;
    private static SiteHierarchy: TrackingSiteHierarchy;
    
    public static init($base: JQuery<HTMLElement>, isSnippetRequest = false): void {
      if (isSnippetRequest) { // no convenience re-init
        return;
      }
      if (typeof window.utag !== 'object') { // wait for utag script to be loaded
        $('#utag').on('load', Tracking.initInternal);
      }
      else {
        Tracking.initInternal();
      }
    }
    
    public static handleOnsiteNavigation(newHeading: string, siblingHeadings: string[] = []) {
      Tracking.logger.trace('handleOnsiteNavigation', newHeading, siblingHeadings);
      if (Tracking.SiteHierarchy) {
        Tracking.SiteHierarchy.handleOnsiteNavigation(newHeading, siblingHeadings);
      }
      else {
        Tracking.earlyOnsiteNavigations.push([newHeading, siblingHeadings]);
      }
    }
    
    private static initInternal() {
      const $root = $(':root');
  
      const dbg = ['dev', 'qa'].indexOf($('body').data('csEnv')) !== -1;
      if (dbg) {
        Tracking.qaLogger.setLevel(log.levels.INFO, false);
      }
      Tracking.SiteHierarchy = new TrackingSiteHierarchy(window.utag_data);
      
      //noinspection JSDeprecatedSymbols
      window.utag_data.page_loadtime =
          String(window.performance.timing.domInteractive - window.performance.timing.domLoading); // deprecated, but only option in IE and Safari
      
      if (window.utag_data.page_type === 'error_404') {
        window.utag_data.referrer_error = document.referrer;
      }
      
      Tracking.trackView(window.utag_data);
    
      $root
        .on(cs.Snippet.EventIdPreAjaxCall, (event: cs.SnippetEventPreAjax) => {
        $('form.trigger-tracking-filter-submit', $(event.targetElement)).each(function () {
          const $form = $(this);
          Tracking.trackFilterSubmit($form);
        });
      }).on(cs.Snippet.EventIdPostReplace, (event: cs.SnippetEventPostReplace) => {
        Tracking.trackSnippetContent(event.replacedTarget);
      }).on(eh.NAV_FLYOUT_EVENTS.FLYOUT_EXPANDED, ($event:JQuery.TriggeredEvent, elem: HTMLElement): void => {
        let params: EventParams = Tracking.buildFlyoutNavigationParams($(elem), 'navi_main_expand');
        params = Tracking.revampEventParams(params);
        Tracking.trackEvent(params);
      }).on(eh.TAB_EVENTS.TAB_CHANGE, ($event: JQuery.TriggeredEvent, tab: ITab): void => {
        const title = $([tab.tab]).filter('*[data-cs-tracking-subtype="tab"]').first().data('csTrackingTitle');
        if (title) {
          // reflect change of submenu in data layer's navigation info
          const key = Tracking.findLastHeading(window.utag_data);
          window.utag_data[key] = title;
        }
      });
      
      $(document).on('click', 'a, button, .eh-trigger-link-target, .eh-area-target-ref, .trigger-tracking-click, .tag-remove, *[data-cs-tracking-no-track="true"]', Tracking.handleClickEvent);
      Tracking.trackForm($root);
      Tracking.ScrollTracking = new ScrollDepthTracker();
    }
    
    private static handleClickEvent($event: TrackedEvent) {
      let $target = $($event.currentTarget);
      const $initialTarget = $($event.target);
      if ($event.__csTrackingProcessed === true
        || $target.hasClass('disabled')
        || $target.hasClass('inactive')
        || $target.selfOrClosest('*[data-cs-tracking-no-track="true"]').length === 1
        || $target.closest('.flowplayer').length === 1
        || $initialTarget.selfOrClosest('*[data-cs-tracking-no-track="true"]').length === 1
        || $initialTarget.selfOrClosest('.disabled').length === 1
        || $initialTarget.selfOrClosest('.inactive').length === 1
        || $target.hasClass('eh-trigger-link-target') && $initialTarget.selfOrClosest('.eh-link-target-exclude').length ===  1
        || $initialTarget.hasClass('marker-cad-item-control')
        ){
        return;
      }
      $event.__csTrackingProcessed = true;
      
      const trackingInfo = Tracking.analyzeEvent($target);
      if (trackingInfo) {
        Tracking.trackEvent(trackingInfo.params);
        
        // Virtual page views ->
        if ($target.data('csTrackingAddToCart')) {
          $event.stopPropagation();
          
          let pageNamePostfix = '';
          if (trackingInfo.params.event_crossselling_origin_product) {
            pageNamePostfix = ' cross selling';
          }
          else if (trackingInfo.params.event_subtype?.indexOf('quick_select_') === 0) {
            pageNamePostfix = ' quick select';
          }
          else if (trackingInfo.params.event_subtype?.indexOf('footer_') === 0) {
            pageNamePostfix = ' footer';
          }
          
          Tracking.trackView($.extend({}, window.utag_data, {
            goal_add_to_cart: 'add_to_cart',
            ec_product_status: 'add',
            ec_product: $target.data('nebpProductMatnr'),
            product_material_id: $target.data('nebpProductMatnr'),
            product_segment: $target.data('csTrackingFlexSegm'),
            product_attribute: ($target.data('nebpProductEdirect') ? 'e-direct' : '-'),
            product_id: $target.data('nebpProductRoot'),
            product_name: trackingInfo.params.event_product_name,
            page_name: Tracking.buildPageName(['product add' + pageNamePostfix]),
            crossselling_origin_product: trackingInfo.params.event_crossselling_origin_product
          }));
        }
        else if (trackingInfo.params.event_name === 'download') {
          $event.stopPropagation();
          $(window.utag_data).removeProp('goal_form_success');
          Tracking.trackView($.extend({}, window.utag_data, {
            goal_direct_download: trackingInfo.params.event_name,
            page_name: Tracking.buildPageName([trackingInfo.params.event_name])
          }));
        }
        else if (trackingInfo.params.event_name === 'download_link_dla') {
          $event.stopPropagation();
          Tracking.trackView($.extend({}, window.utag_data, {
            goal_dla: trackingInfo.params.event_name,
            page_name: Tracking.buildPageName([trackingInfo.params.event_name])
          }));
        }
        else if (trackingInfo.params.event_subtype === 'search_result') {
          const $resultInfo = $target.selfOrClosest('.marker-qs-result-info');
          const key = Tracking.findLastHeading(window.utag_data);
          const headingOverride: { [key: string]: string } = {};
          headingOverride[key] = 'search suggest';
          Tracking.trackView($.extend({}, window.utag_data, headingOverride, {
            search_term: $resultInfo.data('fulltextTerm'),
            search_results: $resultInfo.data('resultCount'),
            page_name: Tracking.buildPageName([], headingOverride)
          }));
        }
        else if ((trackingInfo.params.event_subtype === 'submenu')
          || (
            (trackingInfo.params.event_subtype === 'tab' || trackingInfo.params.event_name === 'tab'
              || trackingInfo.params.event_name === 'subtab')
            && trackingInfo.params.event_pub_spot !== 'PS.FlexGPSNavigator'
            // && trackingInfo.params.event_pub_spot !== 'pc_product-teaser-tab-block' // not matched on homepage
            // && ($target.selfOrClosest('.cs-track-local-nav').length == 0) 
            // tab navigation for flex gps should not trigger virtual page views
            // tab navigation for module Product Teaser Grouped Block should not trigger virtual page views 
            )
        ) {
          $event.stopPropagation();
          const key = Tracking.findLastHeading(window.utag_data);
          const headingOverride: { [key: string]: string } = {};
          headingOverride[key] = $target.data('csTrackingTitle');
          if(trackingInfo.params.event_pub_spot !== 'pc_product-teaser-tab-block') {
            // COM-1726: 
            const nextKey = `site_h${parseInt(key?.substring('site_h'.length) ?? '0', 10) + 1}`;
            headingOverride[nextKey] = 'tab';
          }
          Tracking.trackView($.extend({}, window.utag_data, headingOverride, {
            page_name: Tracking.buildPageName([], headingOverride)
          }));
        }
        else if (trackingInfo.params.event_name === 'subtab') {
          Tracking.logger.debug('virtual page view', trackingInfo);
          Tracking.logger.debug('window.utag_data', window.utag_data);
          
          $event.stopPropagation();
          const key = Tracking.findLastHeading(window.utag_data);
          const headingOverride: { [key: string]: string } = {};
          
          let nextKey = `site_h${parseInt(key?.substring('site_h'.length) ?? '0', 10) + 1}`;
          //headingOverride[nextKey] = 'parent-tab title';
          //nextKey = `site_h${parseInt(nextKey?.substring('site_h'.length) ?? '0', 10) + 1}`;
          headingOverride[nextKey] = trackingInfo.params.event_linkname?.toLowerCase() ?? 'parent-tab title';
          Tracking.trackView($.extend({}, window.utag_data, headingOverride, {
            page_name: Tracking.buildPageName([], headingOverride)
          }));
        }
        else if ($target.hasClass('cs-flex-desc')) {
          $event.stopPropagation();
          const key = Tracking.findLastHeading(window.utag_data);
          const nextKey = `site_h${parseInt(key?.substring('site_h'.length) ?? '0', 10) + 1}`;
          const dataLayer: { [key: string]: string } = $.extend({}, window.utag_data);
          dataLayer[nextKey] = $(`#flex-info-${$target.data('flexSegment') ?? 'desc'}`).data('heading');
          dataLayer.page_name = Tracking.buildPageName([], dataLayer);
          dataLayer.page_type = 'flex';
          Tracking.trackView(dataLayer);
        } else if (trackingInfo.params.event_subtype === 'cad_radio_button') {
          $event.stopPropagation();
        }
        else if ($target.hasClass('trigger-form-open') && $target.data('lightbox') === 'local') {
          Tracking.logger.debug('virtual page view', trackingInfo);
          $event.stopPropagation();
          const key = Tracking.findLastHeading(window.utag_data);
          const nextKey = `site_h${parseInt(key?.substring('site_h'.length) ?? '0', 10) + 1}`;
          const dataLayer: { [key: string]: string } = $.extend({}, window.utag_data);
          dataLayer[nextKey] = $target.data('title');
          dataLayer.page_name = Tracking.buildPageName([], dataLayer);
          Tracking.trackView(dataLayer);
        }
      }
    }
    
    public static trackEvent(params: EventParams): void {
      const dbg = ['dev', 'qa'].indexOf($('body').data('csEnv')) !== -1, proxy = {o: {}, fn: () => {}};
      if (window.utag && typeof window.utag.link === 'function') {
        proxy.o = window.utag;
        proxy.fn = window.utag.link;
      }
      else {
        Tracking.qaLogger.debug('called missing window.utag.link');
      }
      if (dbg) {
        proxy.fn = monitorFunctionCall(proxy.fn, proxy.o);
      }
      fixUDO(proxy.fn, proxy.o).call(proxy.o, params);
    }
  
    public static trackView(params: DataLayer): void {
      const dbg = ['dev', 'qa'].indexOf($('body').data('csEnv')) !== -1, proxy = {o: {}, fn: () => {}};
      if (window.utag && typeof window.utag.view === 'function') {
        proxy.o = window.utag;
        proxy.fn = window.utag.view;
      }
      else {
        Tracking.qaLogger.debug('called missing window.utag.view');
      }
      if (dbg) {
        proxy.fn = monitorFunctionCall(proxy.fn, proxy.o);
      }
      fixUDO(proxy.fn, proxy.o).call(proxy.o, params);
    }
    
    static trackScrollDepth(val:string) {
      let params = {
        event_name: 'scroll_depth',
        scroll_depth: val
      } as EventParams;
      const ep = Tracking.revampEventParams(params);
      Tracking.trackEvent(ep);
    }

    public static injectTrackingEvent(eventParams: {[key: string]: string | undefined | null}, $elem?: JQuery<HTMLElement>, eventNameOverride?: EventName) {
      if ($elem) {
        const trackingInfo = Tracking.analyzeEvent($elem);
        if (trackingInfo) {
          if (trackingInfo.params.event_subtype && eventParams.event_subtype && trackingInfo.params.event_subtype !== eventParams.event_subtype) {
            eventParams.event_subtype = [eventParams.event_subtype, trackingInfo.params.event_subtype].join('_');
          }
          const ep = Tracking.revampEventParams($.extend({}, trackingInfo.params, eventParams,
              eventNameOverride ? {event_name: eventNameOverride} : null));
          Tracking.trackEvent(ep);
        }
      }
      else {
        Tracking.trackView($.extend({}, window.utag_data, eventParams));
      }
    }
    
    private static extractTrackingTitle($elem: JQuery<HTMLElement>, $teaserInfo: JQuery<HTMLElement> = $()) {
      return Tracking.trimStringOrNull(
          $elem.selfOrClosest('*[data-cs-tracking-title]').data('csTrackingTitle')
          || $teaserInfo.data('csTrackingTeaserTitle')
          || $elem.selfOrFind('.eh-label:not(.eh--hide)').text()
          || $elem.filter('a, button, label, span').text()
      );
    }

    private static composeModuleId($elem: JQuery<HTMLElement>) {
      // let subUnit = $elem.closest('.marker-trck-submodule');
      // if(subUnit.length) {
      //   return subUnit.selfOrFind('*[data-trck-submodule]').data('trckSubmodule');
      // }
      let module = $elem.closest('.ehel-module');
      let uuid = module.selfOrFind('*[data-module-uuid]').data('moduleUuid');
      let title = module.selfOrClosest('*[data-cs-tracking-module-title]').data('csTrackingModuleTitle') || module.find('.marker-module-title').text() || '';
      if(uuid) {
        return title +' | '+ uuid;
      }
      return title;
    }

    private static analyzeEvent($elem: JQuery<HTMLElement>): {'target': HTMLElement, 'params': EventParams} | null {
      if (!$elem.get(0)) {
        return null;
      }
      const $linkTarget = $elem.selfOrClosest('.eh-trigger-link-target').find('.eh-link-target');
      if ($linkTarget.length === 1 && $elem.selfOrClosest('.eh-link-target-exclude').length === 0) {
        $elem = $linkTarget;
      }
      const
        $header = $elem.selfOrClosest('.eh-header'),
        $quickSelect = $elem.selfOrClosest('.eh-quickselect'),
        $teaserInfo = $elem.selfOrClosest('.marker-teaser-info'),
        dlaDocumentId = $elem.selfOrClosest('*[data-cs-tracking-dla-document-id]').data('csTrackingDlaDocumentId'),
        crosssellingOriginProduct = $elem.selfOrClosest('*[data-cs-tracking-crossselling]').data('csTrackingCrossselling'),
        fileName = $elem.selfOrClosest('*[data-cs-tracking-file-name]').data('csTrackingFileName'),
        isLightboxVideo = $elem.selfOrClosest('*[data-lightbox]').data('lightbox') === 'video',
        isOverlayClose = $elem.selfOrClosest('.eh-overlay--close').length === 1,
        isPrefixContext = $elem.selfOrClosest('.marker-cs-tracking-prefix-context').length === 1,
        isQuickSelectEnabled = $quickSelect.length === 1 && !$quickSelect.hasClass('eh-quickselect-disabled'),
        isHeaderSticky = $header.selfOrClosest('.is-sticky').length === 1,
        headerEventName = isHeaderSticky ? 'header_sticky' : 'header',
        isFooterSticky = $elem.selfOrClosest('.eh-inject-sticky').hasClass('scrolling-sticky'),
        productBundleId = $elem.selfOrClosest('*[data-cs-tracking-product-bundle-id]').data('csTrackingProductBundleId'),
        productId = $elem.selfOrClosest('*[data-cs-tracking-product-id]').data('csTrackingProductId')
            || ($elem.selfOrClosest('*[data-cs-product-root]').data('csProductRoot') || window.utag_data.product_id),
        productName = $elem.selfOrClosest('*[data-cs-tracking-product-name]').data('csTrackingProductName') || window.utag_data.product_name,
        unitName = $elem.selfOrClosest('.marker-trck-submodule').data('trckSubmodule'),
        psName = (unitName ? unitName+' | ' : '') + $elem.selfOrClosest('*[data-cs-tracking-ps-name]').data('csTrackingPsName'),
        sftFor = $elem.selfOrClosest('*[data-cs-tracking-sft-for]').data('csTrackingSftFor'),
        teasername = Tracking.trimStringOrNull($elem.selfOrClosest('.marker-teaser-area').find('.marker-teaser-name').text()),
        moduleId = this.composeModuleId($elem);
      ;
      let params: EventParams,
        $tmp: JQuery<HTMLElement>,
        linkName = Tracking.extractTrackingTitle($elem, $teaserInfo),
        subtype: string | undefined = $elem.selfOrClosest('*[data-cs-tracking-subtype]').data('csTrackingSubtype') || moduleId,
        targetId: string | undefined = $elem.selfOrClosest('*[data-cs-tracking-target-id]').data('csTrackingTargetId') || $teaserInfo.data('csTrackingTeaserId'),
        siu: string | undefined = $elem.selfOrClosest('*[data-cs-tracking-siu]').data('csTrackingSiu')
      ;
      if (($tmp = $elem.selfOrClosest('.marker-dla-file-data')).length === 1) {
        params = Tracking.buildParamsStandard('radio_button', linkName, psName, productId, productName, productBundleId);
        subtype = 'detail downloads';
      } 
      else if (subtype && subtype === 'View successors') {
        targetId = undefined;
        params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard('content_link', linkName, psName, productId, productName, productBundleId));
      }
      else if ($elem.selfOrClosest('.marker-document-download').length === 1) {
        params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard('download', linkName, psName, productId, productName, productBundleId));
      }
      else if (fileName !== undefined && !isLightboxVideo) {
        params = Tracking.buildParamsStandard('download', linkName, psName, productId, productName, productBundleId);
        subtype = fileName;
      }
      else if (dlaDocumentId !== undefined) {
        params = Tracking.buildParamsStandard('download_link_dla', linkName, psName, productId, productName, productBundleId);
        params.event_outbound_link = $elem.attr('href');
        params.event_document_id = dlaDocumentId;
      }
      else if ($elem.selfOrClosest('.marker-product-actions').length === 1) {
        if (subtype && subtype === 'Show CAD') {
          params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard('show_CAD', linkName, psName, productId, productName, productBundleId));
        } else {
          params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard('product_action', linkName, psName, productId, productName, productBundleId));
        }
        params.event_crossselling_origin_product = crosssellingOriginProduct;
      }
      else if ($elem.selfOrClosest('.trigger-product-comparison-compare').length === 1) {
        params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard('product_action', linkName, psName, productId, productName, productBundleId));
        //@ts-ignore
        if (typeof eh.ProductComparison !== 'undefined') {
          //@ts-ignore
          const pc = eh.ProductComparison.getInstance();
          if (pc) {
            params.event_compareproduct_id = pc.getProductRoots().join(',');
          }
        }
      }
      else if ($elem.selfOrClosest('.add-to-cart').length === 1 || $elem.selfOrClosest('.eh-product-pdf-link').length === 1) {
        params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard('product_action', linkName, psName, productId, productName, productBundleId));
      }
      else if ($elem.selfOrClosest('.marker-copy-clipboard').length === 1 || $elem.selfOrClosest('.marker-send-mail').length === 1) {
        params = Tracking.buildParamsStandard('share', linkName, psName, productId, productName, productBundleId);
      }
      else if ($elem.selfOrClosest('.marker-save-to-list').length === 1) {
        if($elem.hasClass('saved')) {
          let lname = $elem.selfOrFind('.saved-in-list').text() || $elem.selfOrClosest('*[data-cs-tracking-title]').data('csTrackingTitle');
          params = Tracking.buildParamsStandard('product_action', lname, psName, productId, productName, productBundleId);
          subtype = 'Saved to list';
        } else {
          let lname = $elem.selfOrFind('.save-to-list').text() || $elem.selfOrClosest('*[data-cs-tracking-title]').data('csTrackingTitle');;
          params = Tracking.buildParamsStandard('product_action', lname, psName, productId, productName, productBundleId);
          subtype = 'Save to list';
        }
      }
      else if ($elem.selfOrClosest('.marker-logo').length === 1) {
        params = Tracking.buildParamsStandard(headerEventName, $elem.selfOrFind('img').attr('title'), psName, productId, productName, productBundleId);
        subtype = 'logo';
      }
      else if ($elem.selfOrClosest('.marker-subscribe').length === 1) {
        params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard('subscribe', linkName, psName, productId, productName, productBundleId));
      }
      else if ($elem.closest('.eh-country-chooser').length === 1) {
        params = Tracking.buildNavigationParams($elem, 'switch_site');
        params.event_switch_language_site = $.map([$elem.data('csTrackingSite'), $elem.data('csTrackingCountry'),
          $elem.attr('hreflang')], (v) => {
          return v;
        }).join('_');
        params.event_linkname = params.event_switch_language_site;
      }
      else if ($elem.selfOrClosest('.trigger-toggle-navigation').length === 1) {
        params = Tracking.buildParamsStandard(headerEventName, linkName, psName, productId, productName, productBundleId);
        subtype = 'navi_burger';
      }
      else if (($elem.closest('.marker-nav-meta').length === 1)) {
        params = Tracking.buildFlyoutNavigationParams($elem, 'navi_meta_level1');
      }
      else if ($elem.closest('.marker-nav-main').length === 1) {
        let lvl = $elem.closest('.marker-nav-main').data('csTrackingLevel') || '';
        switch(lvl) {
          case 'l3':
            params = Tracking.buildFlyoutNavigationParams($elem, 'navi_main_level3', psName, productId, productName, productBundleId);
            break;
          case 'l2':
            if($elem.hasClass('ehel-navigation--links-item-category')) {
              return null;
            }
            params = Tracking.buildFlyoutNavigationParams($elem, 'navi_main_level2');
            break;
          case 'l1':
            if($elem.hasClass('ehel-navigation--links-item-category')) {
              // by event: params = Tracking.buildFlyoutNavigationParams($elem, 'navi_main_expand');
              return null;
            } else {
              params = Tracking.buildFlyoutNavigationParams($elem, 'navi_main_level1');
            }
            break;
          default:
            return null;
        }
        const href = $elem.attr('href');
        if (!!href)  {
          const formParamMap = eh.URLHelper.buildNormalizedQueryParamMap(eh.URLHelper.parse(href).search, true);
          const formParams = Object.keys(formParamMap).map(k => {
            return {'k': k, 'v': Array.isArray(formParamMap[k]) ? formParamMap[k][0] : formParamMap[k] as string}; 
          });
          $.extend(params, Tracking.analyzeFlyoutParams(formParams));
        }
      }
      else if ($elem.closest('.marker-nav-breadbrumb').length === 1) {
        const paramsTmp = Tracking.buildParamsBreadcrumbNavi($elem, productId, productName);
        if (!paramsTmp) {
          return null;
        }
        params = paramsTmp;
      }
      else if ($elem.selfOrClosest('.marker-nav-tab').length === 1) {
        params = Tracking.buildParamsStandard('tab', linkName, psName, productId, productName, productBundleId);
      }
      else if ($elem.selfOrClosest('.marker-nav-subtab').length === 1) {
        params = Tracking.buildParamsStandard('subtab', linkName, psName, productId, productName, productBundleId);
      }
      else if ($elem.closest('.marker-footer-social').length === 1) {
        params = Tracking.buildParamsSocialMedia($elem);
      }
      else if ($elem.selfOrClosest('.cartContainer').length === 1) {
        params = Tracking.buildParamsStandard(headerEventName, 'view_cart', psName, productId, productName, productBundleId);
        subtype = 'icon_cart';
      }
      else if ($elem.selfOrClosest('.myAccountContainer').length === 1) {
        const loggedIn = $elem.selfOrClosest('.myAccountContainer').hasClass('is-logged-in');
        params = Tracking.buildParamsStandard(headerEventName, linkName, psName, productId, productName, productBundleId);
        subtype = loggedIn ? 'icon_myeh' : 'login';
      }
      else if (($elem.attr('href') || '').substring(0, 7) === 'mailto:') {
        params = Tracking.buildParamsStandard('email', linkName, psName, productId, productName, productBundleId);
        params.goal_direct_contact = 'direct_contact';
      }
      else if (($elem.attr('href') || '').substring(0, 4) === 'tel:') {
        params = Tracking.buildParamsStandard('phone', ($elem.attr('href') || '').substring(4), psName, productId, productName, productBundleId);
        params.goal_direct_contact = 'direct_contact';
      }
      else if ($elem.hasClass('csProdSearch__reset') || $elem.hasClass('tag-remove') || $elem.hasClass('filter_param_desc')) {
        if ($elem.hasClass('filter_param_desc') && !$elem.hasClass('is-open')) {
          return null; // don't track when closing tooltip (and .is-open has just been removed)
        }
        params = Tracking.buildParamsStandard('filter', $elem.hasClass('tag-remove') ? 'remove filter tag'
          : $elem.hasClass('filter_param_desc') ? 'information' : linkName, psName, productId, productName, productBundleId);
      }
      else if ($elem.selfOrClosest('.eh-product-quick-filter--item').length === 1
        || $elem.selfOrClosest('.eh-product-filter--item').length === 1
        || $elem.selfOrClosest('.trigger-reset-search').length === 1
        || $elem.selfOrClosest('.trigger-filter-display').length === 1) {
          params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard('filter', linkName, psName, productId, productName, productBundleId));
      }
      else if (($elem.hasClass('js-overlay-close') && $elem.closest('.marker-country-redirect').length === 1)
           || (isOverlayClose && $('.marker-country-redirect', $elem.closest('.eh-overlay')).length === 1)) {
        params = Tracking.buildParamsStandard('re-routing', linkName, psName, productId, productName, productBundleId);
        subtype = $elem.selfOrClosest('.eh-overlay').find('*[data-cs-tracking-subtype]').data('csTrackingSubtype');
      }
      else if ($elem.selfOrClosest('.marker-imagemap').length === 1) {
        params = Tracking.buildParamsStandard('imagemap', linkName, psName, productId, productName, productBundleId);
        const tn = $elem.selfOrClosest('*[data-cs-tracking-teasername]').data('csTrackingTeasername');
        if (tn) {
          params.event_linkposition_teasername = tn;
        }
      }
      else if ($elem.selfOrClosest('.marker-image-lightbox').length === 1) {
        params = Tracking.buildParamsStandard('image', linkName, psName, productId, productName, productBundleId);
        subtype = 'expand';
      }
      else if (($tmp = $elem.closest('.marker-country-redirect')).length === 1) {
        params = Tracking.buildParamsStandard('re-routing', linkName, psName, productId, productName, productBundleId);
        const country = Tracking.trimStringOrNull($('select#country option:selected', $tmp).text()),
            language = Tracking.trimStringOrNull($('select#language option:selected', $tmp).text())
        ;
        params.event_overlay_selection = country + (language ? ', ' + language : '');
      }
      else if($elem.hasClass('marker-disclaimer-close')) {
        params = Tracking.buildParamsStandard('cookie_layer', 'Close', psName, productId, productName, productBundleId);
        subtype = 'close';
      }
      else if ($elem.selfOrClosest('.marker-cookie-disclaimer').length === 1) {
        params = Tracking.buildParamsStandard('cookie_layer', linkName, psName, productId, productName, productBundleId);
      }
      else if ($elem.selfOrClosest('.eh-login-button').length === 1) {
        params = Tracking.buildParamsStandard(headerEventName, linkName, psName, productId, productName, productBundleId);
        params.event_subtype = $elem.selfOrClosest('.eh-login-button').hasClass('is-logged-in') ? 'icon_myeh' : 'login';
      }
      else if ($elem.selfOrClosest('.marker-login-link').length === 1) {
        params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard('login', linkName, psName, productId, productName, productBundleId));
      }
      else if (($tmp = $elem.selfOrClosest('.cs-flex-info')).length === 1 || ($tmp = $elem.selfOrClosest('.marker-flex-info')).length === 1) {
        params = Tracking.buildParamsStandard('flex', linkName, psName, productId, productName, productBundleId);
        subtype = $tmp.data('flexSegment');
        targetId = undefined;
      }
      else if (($tmp = $elem.selfOrClosest('.js-trigger-hint')).length === 1) {
        params = Tracking.buildParamsStandard('info_icon', linkName, psName, productId, productName, productBundleId);
        subtype = 'Info icon';
        targetId = undefined;
      }
      else if ($elem.selfOrClosest('.eh-product-specs-at-a-glance--close-button').length === 1) {
        params = Tracking.buildParamsStandard('show_alternative', linkName, psName, productId, productName, productBundleId);
        params.event_subtype = 'close';
      }
      else if ($elem.selfOrClosest('.eh-product-specs-at-a-glance--open-button').length === 1) {
        params = Tracking.buildParamsStandard('show_alternative', linkName, psName, productId, productName, productBundleId);
        params.event_subtype = 'open';
      }
      else if (($tmp = $elem.selfOrClosest('.trigger-details-toggle')).length === 1) {
        const $activeElem = $tmp.selfOrFind('.eh-label--state-inactive:hidden').emptyToOptional() ??
          $tmp.selfOrFind('.eh-label--state-active:hidden');
        linkName = this.extractTrackingTitle($activeElem);
        linkName = linkName || $elem.selfOrFind('*[data-cs-tracking-title]:hidden').data('csTrackingTitle');
        params = Tracking.buildParamsStandard('show_more', linkName, psName, productId, productName, productBundleId);
        subtype = $activeElem.selfOrClosest('*[data-cs-tracking-subtype]').data('csTrackingSubtype');
      }
      else if (($tmp = $elem.selfOrClosest('.marker-details-toggle')).length === 1) {
        const $activeElem = $tmp.selfOrFind('.eh-label--state-inactive:hidden').emptyToOptional() ??
          $tmp.selfOrFind('.eh-label--state-active:hidden');
        linkName = this.extractTrackingTitle($activeElem);
        linkName = linkName || $elem.selfOrFind('*[data-cs-tracking-title]:hidden').data('csTrackingTitle');
        if($activeElem.length > 0) {
          subtype = $activeElem.selfOrClosest('*[data-cs-tracking-subtype]').data('csTrackingSubtype') ;
        }
        params = Tracking.buildParamsStandard('show_more', linkName, psName, productId, productName, productBundleId);
      }
      else if ($elem.selfOrClosest('.eh-accordion-ctrl--category-item').length === 1) {
        linkName = $elem.selfOrFind('.eh-accordion-ctrl--category-heading-label').text();
        params = Tracking.buildParamsStandard('accordion', linkName, psName, productId, productName, productBundleId);
        subtype = $elem.selfOrFind('.is-open').length === 1 ? 'open' : 'close';
      }
      else if ($elem.selfOrClosest('.ehel-show-more--button').length === 1) {
        params = Tracking.buildParamsStandard('accordion', linkName, psName, productId, productName, productBundleId);
        subtype = 'open';
      }
      else if ($elem.selfOrClosest('.ehel-show-less--button').length === 1) {
        params = Tracking.buildParamsStandard('accordion', linkName, psName, productId, productName, productBundleId);
        subtype = 'close';
      }
      else if ($elem.selfOrClosest('.eh-slider-navigator--backward, .eh-slider-navigator--forward, ' +
          '.eh-slider-navigator--item, .eh-slider-navigator--next, .eh-slider-navigator--previous, ' +
          '.eh-slider-navigator--item, .eh-simple-navigator--indicator-bullet').length === 1) {
        const eventType: EventName = 'slide',
            map: {[key:string]: string} = {
              'eh-slider-navigator--backward': 'thumb_btn_left',
              'eh-slider-navigator--forward': 'thumb_btn_right',
              'eh-slider-navigator--item': 'thumbnail',
              'eh-slider-navigator--next': 'btn_right',
              'eh-slider-navigator--previous': 'btn_left'
            };
  
        for (let markerClass in map) {
          if ($elem.hasClass(markerClass)) {
            linkName = (markerClass === 'eh-slider-navigator--item'
                && $elem.hasClass( 'eh-simple-navigator--indicator-bullet')) ? 'bullet_navigation' : map[markerClass];
            break;
          }
        }
        params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard(eventType, linkName, psName, productId, productName, productBundleId));
      }
      else if ($elem.selfOrClosest('.ehel-gallery--indicators').length === 1) {
        const eventType: EventName = 'slide',
            map: {[key:string]: string} = {
              'ehel-gallery--prev-btn':'btn_up',
              'ehel-gallery--next-btn':'btn_down',
              'ehel-gallery--indicator':'image_navigation',
            };

        for (let markerClass in map) {
          if ($elem.hasClass(markerClass)) {
            linkName = map[markerClass];
            break;
          }
        }
        params = Tracking.buildParamsStandard(eventType, linkName, psName, productId, productName, productBundleId);
      }
      else if($elem.selfOrClosest('.marker-track-overlay--close').length === 1) {
        let ov = $elem.selfOrClosest('.eh-overlay');
        let info= $elem.selfOrClosest('.eh-overlay').selfOrFind('.marker-track-overlay-closing-info');
        if(info.length === 1) {
          if(info.hasClass('cs-lightbox--picture')) {
            this.injectTrackingEvent({event_subtype: 'shrink', event_name: 'image'}, info);
          }
        }
        return null;
      }
      else if (($tmp = $elem.selfOrClosest('.marker-filter-link')).length === 1) {
        params = Tracking.buildParamsStandard('filter_link', linkName, psName, productId, productName, productBundleId);
        const href = $tmp.attr('href');
        if (href) {
          const formParamMap = eh.URLHelper.buildQueryParamMap(eh.URLHelper.parse(href).search);
          const formParams = Object.keys(formParamMap).map(k => {return {'k': k, 'v': Array.isArray(formParamMap[k]) ? formParamMap[k][0] : formParamMap[k] as string}; });
          $.extend(params, Tracking.analyzeFilterParams(formParams, 'event_filter_'));
        }
      }
      else if ($elem.selfOrClosest('.marker-show-all').length === 1) {
        params = Tracking.buildParamsStandard('show_all', linkName, psName, productId, productName, productBundleId);
      }
      else if ($elem.selfOrClosest('.marker-show-drawing').length === 1) {
        params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard('show_drawing', linkName, psName, productId, productName, productBundleId));
      }
      else if ($elem.selfOrClosest('.marker-cta-section').length === 1) {
        params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard('cta_button', linkName, psName, productId, productName, productBundleId));
      }
      else if ($elem.selfOrClosest('.marker-anchor-navigation').length === 1) {
        params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard('anchor', linkName, psName, productId, productName, productBundleId));
      }
      else if ($elem.selfOrClosest('.marker-teaser-ref-all').length === 1) {
        params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard('teaser', linkName, psName, productId, productName, productBundleId));
      }
      else if ((subtype && ['search_button', 'search_bar_start'].indexOf(subtype) > -1)
          || subtype?.indexOf('quicksearch_overlay_') === 0
          || subtype?.indexOf('search_page_result_') === 0) {
        params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard('search', linkName, psName, productId, productName, productBundleId));
        if (subtype === 'search_button') {
          subtype = undefined;
        }
      }
      else if (($teaserInfo.length === 1) && !(subtype && ['view online', 'watch online'].indexOf(subtype) > -1)) {
        const eventName = psName === 'PS.OfferingTeaser - Home' ? 'navi_teaser' : 'teaser';
        params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard(eventName, linkName, psName, productId, productName, productBundleId));
        if (psName === 'PS.MarketProvenEvidence') {
          subtype = 'success_stories';
        }
        if (subtype === 'cross_selling') {
          params.event_crossselling_origin_product = crosssellingOriginProduct;
        }
        siu && this.appendSiuAdds(siu, params, $elem);
      }
      else if (subtype && subtype === 'cad_radio_button') {
        params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard('radio_button', linkName, psName, productId, productName, productBundleId));
      }
      else {
        const map: {[key:string]: string} = {
          'marker-footer-legal': 'footer_legal',
          'marker-footer-nav': 'footer_main_navigation',
          'marker-footer-quicklinks': 'footer_quick_links'
        };
        //marker-footer-contact
        if (psName === 'PS.MarketProvenEvidence') {
          subtype = 'success_stories';
        }
        for (let markerClass in map) {
          if ($elem.closest(`.${markerClass}`).length === 1) {
            subtype = map[markerClass];
            break;
          }
        }
        let eventName:EventName = (subtype && ['more', 'less'].indexOf(subtype) > -1) ? 'show_more' : 'content_link';
        eventName = (subtype && subtype === 'submit') ? 'form' : eventName;

        params = Tracking.appendParamsExtern($elem, Tracking.buildParamsStandard(eventName, linkName, psName, productId, productName, productBundleId));
        if (teasername !== undefined) {
          params.event_linkposition_teasername = teasername;
        }
      }
      params.event_sft_for = sftFor;
      params.event_target_id = params.event_target_id || targetId;
      params.event_subtype = params.event_subtype || subtype;
      
      if (isQuickSelectEnabled) {
        const trackingInfo = $elem.selfOrClosest( '.marker-cs-tracking-info').data('quickSelectTrackingInfo');
        if (trackingInfo) {
          params.event_order_code = params.event_order_code ?? trackingInfo.orderCode ?? productId;
          if (!params.event_configuration_step) {
            if (trackingInfo.fullyConfigured) {
              params.event_configuration_step = 'fully configured';
            }
            else if (trackingInfo.currentStepIndex) {
              params.event_configuration_step = `step ${trackingInfo.currentStepIndex}: ${trackingInfo.currentStepFeature ?? '00'}`;
            }
          }
          const $btn = $elem.selfOrClosest('.eh-quickselector--toggle-row, .eh-quickselector--product-result-details-back-button');
          let isShowMoreOrLess = $btn.length === 1;
          if (isShowMoreOrLess) {
            params.event_linkname = $btn.text().trim();
          }
          const $toggleButton = $elem.selfOrClosest('.eh-quickselector--toggle-button');
          if ($toggleButton.length === 1) {
            isShowMoreOrLess = true;
            params.event_subtype = ($elem.selfOrClosest('.is-open').length === 1) ? 'quick_select_more' : 'quick_select_less';
            params.event_linkname = $('.eh-label--state-inactive:hidden, .eh-label--state-active:hidden', $toggleButton).text().trim();
          }
          if (isShowMoreOrLess) {
            params.event_name = 'show_more';
          }
        }
        else if ($elem.selfOrClosest('.marker-configure-action').length === 1) {
          delete params.event_subtype;
        }
        if (isPrefixContext && !isFooterSticky && params.event_subtype) {
          params.event_subtype = 'quick_select_' + params.event_subtype;
        }
      }
      else if ($quickSelect.length === 1 && $elem.selfOrClosest('.add-to-cart').length === 1) {
        delete params.event_subtype;
      }
  
      const $trackingInfo = $elem.selfOrClosest('.marker-cs-tracking-info');
      if ($trackingInfo.length === 1) {
        params.event_document_doc_context = $trackingInfo.data('csTrackingDocumentContext');
        params.event_document_doc_type = $trackingInfo.data('csTrackingDocumentType');
        params.event_document_id = $trackingInfo.data('csTrackingDocumentId');
        params.event_download_language = $trackingInfo.data('csTrackingDownloadLanguage');
        params.event_download_type = $trackingInfo.data('csTrackingDownloadType');
        params.event_download_version = $trackingInfo.data('csTrackingDownloadVersion');
        params.event_headline_download = $trackingInfo.data('csTrackingDownloadTitle');
      }
      if (isPrefixContext && isFooterSticky && params.event_subtype) {
        params.event_subtype = 'footer_' + params.event_subtype;
      }
      if(subtype && subtype.indexOf('search_page_result_', 0) === 0) {
        $elem.selfOrClosest('.marker-quicksearch-results').each((_index, el) => {
          const $resultInfo = $(el);
          params.search_term =  $resultInfo.data('fulltextTerm')
        });
      }
      return {
        target: $elem.get(0)!,
        params: Tracking.revampEventParams(params)
      };
    }
    
    private static revampEventParams(params: EventParams): EventParams {
      $.each(params, (k, v) => {  // filter empty values
        if (v === null || v === undefined || v.length === 0) {
          delete params[k];
        }
        else {
          params[k] = String(v).trim().replace(RegExp('\\s+', 'g'), ' ');
        }
        return true;
      });
      params.event_id = Tracking.buildEventId(params);
      return params;
    }
  
    private static buildEventId(params: EventParams): string {
      const d = window.utag_data, arr = [
        d.app_name,
        d.site_country,
        d.site_language,
        d.site_h1,
        d.site_h2,
        d.site_h3,
        d.site_h4,
        d.site_h5,
        d.site_h6,
        d.site_h7,
        params.event_name,
        params.event_linkname
      ];
      return $.map(arr, function (item) {
        return item;
      }).join('.');
    }
  
    public static trackForm($base: JQuery<HTMLElement>, isSnippetRequest = false) {
      $('form.marker-tracking-form', $base).each((_index, elem) => {
        const $form = $(elem),
          $fields = $form.find('input, select, textarea'),
          formName = Tracking.buildFormAction($form.data('csTrackingFormName'));
        let errorFields = $form.data('csTrackingErrorFields');
        if(!errorFields) {
          errorFields = $fields.filter('.is-invalid').map((_index, elem) => {
            return $(elem).data('trackId') || elem.id || $(elem).attr('name'); 
          }).get().join('|');
        }
        let params: EventParams = {
          event_name: 'form',
          event_pub_spot: $form.selfOrClosest('*[data-cs-tracking-ps-name]').data('csTrackingPsName'),
          form_asset_id: $form.data('fid')
        };
        
        if (errorFields) {
          params.event_forms_error_message = $('.eh-validation-msg, .ehtw-form--error-message', $form).map((_index, elem) => {
            return $(elem).text();
          }).get().join(',');
          params.event_forms_errors = errorFields;
          params.event_forms_action = formName + '_error';
          Tracking.trackEvent(Tracking.revampEventParams(params));
        }
        else {
          const handleFieldFocus = () => {
            $fields.off('focus', handleFieldFocus);
            params.event_forms_action = formName + '_start';
            Tracking.trackEvent(Tracking.revampEventParams(params));
          };
          $fields.on('focus', handleFieldFocus);
        }
        if($form.hasClass('marker-track-fields')) {
          const handleFieldInteraction = ($event: TrackedEvent) =>  {
            let $target = $($event.currentTarget);
            let $initialTarget = $($event.target);
            if ($event.__csTrackingProcessed === true
              || $target.hasClass('disabled')
              || $target.hasClass('inactive')
              || $target.selfOrClosest('*[data-cs-tracking-no-track="true"]').length === 1
              || $target.closest('.flowplayer').length === 1
              || $initialTarget.selfOrClosest('*[data-cs-tracking-no-track="true"]').length === 1
              || $initialTarget.selfOrClosest('.disabled').length === 1
              || $initialTarget.selfOrClosest('.inactive').length === 1
              || $target.hasClass('eh-trigger-link-target') && $initialTarget.selfOrClosest('.eh-link-target-exclude').length ===  1
            ){
              return;
            }
            $event.__csTrackingProcessed = true;

            let evParams: EventParams = {
              event_name: 'form',
              event_pub_spot: $form.selfOrClosest('*[data-cs-tracking-ps-name]').data('csTrackingPsName')
            };
            if($target.is('select')) {
              evParams.event_linkname = $target.find('option:selected').text();
              let tid = $target.data('trackId') || $target.attr('id');
              evParams.event_subtype = tid+':'+$target.val();
              Tracking.trackEvent(Tracking.revampEventParams(evParams));
            } else {
              let inp = $target.selfOrFind('input:not(:hidden)') || $target.parent().find('input:not(:hidden)');
              let $label = inp.parent().selfOrFind('label');
              evParams.event_linkname = $label.text();
              if(inp) {
                let tid = inp.data('trackId') || inp.attr('id');
                if(inp.is(':radio')) {
                  evParams.event_subtype = tid+':'+inp.val();
                } else if(inp.is(':checkbox')) {
                  evParams.event_subtype = tid+':'+(inp.prop('checked') ?'check' : 'uncheck');
                } else if(inp.is(':text')) {
//                  if($event.type === 'change') {
//                   evParams.event_subtype = tid + ":change" ;
//                  } else if($event.type === 'focusout') {
//                    evParams.event_subtype = tid + ":autofill";
//                  } else {
                    evParams.event_subtype = tid;
//                  }
                }
                if(evParams.event_subtype) {
                  Tracking.trackEvent(Tracking.revampEventParams(evParams));
                }
              }
            }
          };
          $form.find('select').on('change', handleFieldInteraction);
          $form.find('input:text, input:radio, input:checkbox').on('click', handleFieldInteraction);
          $form.find('input:text').parent().selfOrFind('label').on('click', handleFieldInteraction);
          $form.find('input:text').on('keyup', (ev) => {// keyup is only on focus gained
            var code = ev.keyCode || ev.which;
            if (code == 9) {
              handleFieldInteraction(ev);
            }
          });
          $form.find('input:text').on('change', (ev) => {
            handleFieldInteraction(ev);
          });
        }
      });
  
      $('.marker-form-success', $base).each((_index, elem) => {
        const $elem = $(elem),
          params: EventParams = {
          event_name: 'form',
          event_forms_action: Tracking.buildFormAction($elem.data('csTrackingFormName') + '_success'),
          event_pub_spot: $elem.selfOrClosest('*[data-cs-tracking-ps-name]').data('csTrackingPsName'),
          form_asset_id: $elem.data('fid')
        };
        Tracking.trackEvent(Tracking.revampEventParams(params));
        
        if (isSnippetRequest) {
          Tracking.trackView($.extend({}, window.utag_data, {
            goal_form_success: $elem.data('csTrackingFormSuccessGoal') || 'form_successful',
            page_name: Tracking.buildPageName(['form successful']),
            form_asset_id: $elem.data('fid')
          }));
        }
      });
    };
  
    private static buildFormAction(formName: string): string {
      const d = window.utag_data, arr = [
        d.app_name,
        d.site_country,
        d.site_language,
        formName
      ];
      return $.map(arr, function (item) {
        return item;
      }).join('.');
    }
  
    private static buildPageName(additionalSegments: string[], dataLayerOverrides = {}): string {
      const d = $.extend({}, window.utag_data, dataLayerOverrides), arr = [
        d.app_name,
        d.site_country,
        d.site_language,
        d.site_h1,
        d.site_h2,
        d.site_h3,
        d.site_h4,
        d.site_h5,
        d.site_h6,
        d.site_h7
      ].concat(additionalSegments || []);
      return $.map(arr, function (item) {
        return item;
      }).join('.');
    }
  
    private static buildParamsStandard(eventName: EventName, linkName?: string, psName?: string, productId?: string, productName?: string, productBundleId?: string): EventParams {
      return {
        event_name: eventName,
        event_linkname: linkName,
        event_pub_spot: psName,
        event_product_id: productId,
        event_product_name: productName,
        event_bundle_id: productBundleId
      };
    }
  
    private static buildNavigationParams($navElem: JQuery<HTMLElement>, naviType: EventName): EventParams {
      const successful = !!$navElem.attr('href'), targetId = $navElem.data('csTrackingTargetId'),
          $segments = $navElem.add($navElem.parents()).map(function () {
            const $el = $(this);
            let $r;
            $r = $el.filter('a');
            if ($r.length > 0) {
              return $r;
            }
            $r = $el.filter(function () {
              return !!$(this).data('csTrackingTitle');
            });
            if ($r.length > 0) {
              return $r;
            }
            return null;
          }),
          segments = $segments.map(function () {
            return Tracking.trimStringOrNull($(this).data('csTrackingTitle') || $(this).text());
          }).get()
      ;
      if ($navElem.parents('h3, article').length !== 0) {
        segments.splice(segments.length - 1, 0, 'spot');
      }
      if (targetId) {
        segments.push(targetId);
      }
      return Tracking.appendParamsExtern($navElem, {
        event_name: naviType,
        event_pub_spot: $navElem.data('csTrackingPsName'),
        event_navpath: successful ? segments.join('.') : null,
        event_target_id: successful ? targetId : null,
        event_linkname: $navElem.filter('a').map(function () {
          return Tracking.trimStringOrNull($(this).data('csTrackingTitle') || $(this).text());
        }).get()[0],
        event_subtype: $navElem.data('csTrackingTitleNormalized')
      });
    }

    private static buildFlyoutNavigationParams($navElem: JQuery<HTMLElement>, naviType: EventName, psname?: string, productId?:string, productName?: string, productBundleId?:string): EventParams {
      const successful = !!$navElem.attr('href'), targetId = $navElem.data('csTrackingTargetId');
      const navWrap = $navElem.closest('.ehel-navigation');
      let subtype: string | undefined = $navElem.selfOrClosest('*[data-cs-tracking-subtype]').data('csTrackingSubtype');
      var pathElems = $navElem;
      let navCont = $navElem.closest('.marker-nav-main, .marker-nav-meta'), sel, navPar;

      while(navCont && (sel = navCont.data('subNavigationMenu')) != null) {
        navPar = navWrap.find(`[data-navigation-menu="${sel}"]`);
        if(navPar) {
          pathElems = pathElems.add(navPar);
        }
        navCont = navPar?.closest('.marker-nav-main');
      }
      const $segments = pathElems.map(function () {
            const $el = $(this);
            let $r;
            $r = $el.filter('a');
            if ($r.length > 0) {
              return $r;
            }
            $r = $el.filter(function () {
              return !!$(this).data('csTrackingTitleNormalized');
            });
            if ($r.length > 0) {
              return $r;
            }
            return null;
          }),
          segments = $segments.map(function ($seg) {
            return Tracking.trimStringOrNull($(this).data('csTrackingTitleNormalized') || $(this).text());
          }).get();
      ;

      if(subtype && subtype === 'navi_main_link') {
        if(segments.length > 0) {
          segments[segments.length-1] = 'shop all';
        }
      }
      return Tracking.appendParamsExtern($navElem, {
        event_name: naviType,
        event_pub_spot: psname,
        event_navpath: successful ? segments.join('.') : null,
        event_target_id: successful && ('navi_main_expand' != naviType) ? targetId : null,
        event_bundle_id: productBundleId,
        event_product_id: productId,
        event_product_name: productName,
        event_linkname: $navElem.filter('a').map(function () {
          return Tracking.trimStringOrNull($(this).data('csTrackingTitle') || $(this).text());
        }).get()[0],
        event_subtype: subtype || $navElem.data('csTrackingTitleNormalized')
      });
    }

  
    private static buildParamsBreadcrumbNavi($navElem: JQuery<HTMLElement>, productId?: string, productName?: string): EventParams | null {
      if (!$navElem.attr('href')) {
        return null;
      }
      const targetId = $navElem.data('csTrackingTargetId');
      const segments = $navElem.closest('ul').find('li a:visible')
          .add($navElem.closest('ol').find('li'))
          .map((_index, el) => Tracking.trimStringOrNull($(el).data('csTrackingTitleNormalized') || $(el).text()))
          .filter((_index, str) => str.length > 0)
          .get()
      ;
      if (targetId) {
        segments.push(targetId);
      }
      
      return Tracking.appendParamsExtern($navElem, {
        event_name: 'breadcrumb',
        event_pub_spot: $navElem.data('csTrackingPsName'),
        event_navpath: segments.join('.'),
        event_linkname: Tracking.extractTrackingTitle($navElem),
        event_target_id: targetId,
        event_product_id: productId,
        event_product_name: productName
      });
    }
  
    private static buildParamsSocialMedia($elem: JQuery<HTMLElement>): EventParams {
      return {
        event_name: 'socialmedia',
        event_linkname: Tracking.trimStringOrNull($elem.data('csTrackingTitle') || $elem.text()),
        event_subtype: 'footer_socialmedia'
      };
    }
  
    private static appendParamsExtern($elem: JQuery<HTMLElement>, params: EventParams): EventParams {
      const href = $elem.attr('href') || '',
          targetHostname = $elem.prop('hostname'),
          external = !!targetHostname && targetHostname !== window.location.hostname,
          otherApp = external && new RegExp('^https?://[^/]+\\.endress\\.com(\\.cn)?(:[0-9]+)?($|/|\\?|#).*').test(href),
          goals: string | string[] = $elem.closest('*[data-cs-tracking-goals]').data('csTrackingGoals')
      ;
      if (goals) {
        if (Array.isArray(goals)) {
          $.each(goals, function (index, value) {
            params['goal_' + value] = value;
          });
        }
        else {
          params['goal_' + goals] = goals;
        }
      }
      if (external) {
        params.event_leaving_website = otherApp ? 'exit_other_application' : 'exit_non_endress';
        params.event_outbound_link = href || null;
        params.event_target_id = $elem.data('csTrackingTargetId');
      }
      return params;
    }
  
    private static trimStringOrNull(text: string): string {
      return text.length > 0 ? text.trim() : text;
    }
  
    private static findLastHeading(stack: {[key: string]: string}) {
      const prefix = 'site_h';
      let i = 1;
      let last: string | undefined;
      do {
        last = prefix + i;
        i++;
      }
      while (i < 10 && stack[prefix + i] !== undefined);
      return last;
    }
        
    private static trackSnippetContent($base: JQuery<HTMLElement>): void {
      if($('.ehel-product-finder--results', $base).length > 0) {
        return;
      }
      if($('.ehel-filter-block--results', $base).length > 0) {
        return;
      }
      $('.marker-result-info', $base).each((_index, el) => {
        const $resultInfo = $(el);
        Tracking.trackView($.extend({}, window.utag_data, {
          search_term: $resultInfo.data('fulltextTerm'),
          search_results: $resultInfo.data('resultCount')
        }));
      });
      Tracking.trackForm($base, true);
    }
  
    private static trackFilterSubmit($form: JQuery<HTMLElement>): void {
      const formParams = Tracking.collectFormParams($form),
        productBundleId = $form.selfOrClosest('*[data-cs-tracking-product-bundle-id]').data('csTrackingProductBundleId'),
        productId = $form.selfOrClosest('*[data-cs-tracking-product-id]').data('csTrackingProductId')
          || ($form.selfOrClosest('*[data-cs-product-root]').data('csProductRoot') || window.utag_data.product_id),
        productName = $form.selfOrClosest('*[data-cs-tracking-product-name]').data('csTrackingProductName') || window.utag_data.product_name,
        psName = $form.selfOrClosest('*[data-cs-tracking-ps-name]').data('csTrackingPsName'),
        params = Tracking.buildParamsStandard('filter', undefined, psName, productId, productName, productBundleId),
        change = $form.data('csTrackingAjaxChange'),
        subtype = (change == 'filter_reset') ? 'reset filter' : (change == 'filters_clear')? 'reset filter' : 'filter_click';
      ;
      if (formParams.length === 0) {
        return;
      }
      if (subtype) {
        if(subtype == 'reset filter') {// pc prodFinder
          let linkName = (change == 'filters_clear')? Tracking.extractTrackingTitle($form.find('.marker-filters-clear')) : undefined;
          let paramsExtra: EventParams = Tracking.buildParamsStandard('filter', linkName, psName, productId, productName, productBundleId);
          paramsExtra.event_subtype = subtype;
          Tracking.trackEvent(Tracking.revampEventParams(paramsExtra));
        }        
        params.event_subtype = subtype;
      }
      $.extend(params, Tracking.analyzeFilterParams(formParams, 'event_filter_'));
  
      if ($form.data('csTrackingIsSnippetRequest') !== true) {
        Tracking.trackView($.extend({}, window.utag_data, {
          page_name: Tracking.buildPageName([params.event_name])
        }));
      }
      
      Tracking.trackEvent(Tracking.revampEventParams(params));
    }
    
    
    public static collectFormParams($form: JQuery<HTMLElement>): { 'k': string; 'v': string; }[] {
      $form.find('input[type="text"]').each(function () {
        const $i = $(this);
        if ($i.val() === $i.attr('placeholder')) {
          $i.val('');
        }
      });
      let noTrack = ($form.data('csTrackingNotrackParameter') || '').split(',');
      return $.map($form.formSerialize().split('&'), function (kvStr) {
        const kv = kvStr.split('=');
        if (!kv[1]) {
          return undefined;
        }
        if(noTrack.indexOf(kv[0]) > -1) {
          return undefined;
        }
        const k = decodeURIComponent(kv[0]);
        if (k.substring(k.length - 9) === '_original' && $form.find('input[type="hidden"][name="' + k + '"]').length === 1
            && $form.find('input[type="checkbox"][name="' + k.substring(0, k.length - 9) + '"]').length === 1) {
          return undefined;
        }
        // cut off scope
        const sepPos = k.indexOf('.');
        return {'k': (sepPos === -1 ? k : k.substring(sepPos + 1)).replace(/\[\d*]$/g, ''), 'v': decodeURIComponent(kv[1])};
      })
    }
    
    
    public static analyzeFilterParams(formParams: { 'k': string; 'v': string; }[], filterPrefix = ''): {[key: string]: string | undefined | null} {
      let params: {[key: string]: string | undefined | null} = {};
      const sep1 = ',', sep2 = '|', sep3 = ':';
      let filterMap: {[key: string]: string[]} = {}, filterNames: string[], filterValues: string[] = [], filterCriteria: string[] = [];
      $.each(formParams, function () {
        if (filterMap[this.k] === undefined) {
          filterMap[this.k] = [this.v];
        }
        else {
          filterMap[this.k].push(this.v);
        }
      });
      filterNames = $.map(filterMap, function (_v, k: string) {
        return k;
      });
      filterNames.sort();
      $.map(filterNames, function (k) {
        filterMap[k].sort();
        filterValues = filterValues.concat(filterMap[k]);
        filterCriteria.push([k, filterMap[k].join(sep1)].join(sep3));
        if (filterPrefix !== '') {
          params[(filterPrefix + k).replace(/-/g, '_')] = filterMap[k].join(sep1);
        }
      });
      params.event_filter_groups = filterNames.join(sep1);
      params.event_filter_criteria = filterValues.join(sep1);
      params.event_filter_combination = filterCriteria.join(sep2);
      params.event_filter_amount_groups = String(filterNames.length);
      return params;
    }
    
    public static analyzeFlyoutParams(formParams: { 'k': string; 'v': string; }[]): {[key: string]: string | undefined | null} {
      let params: {[key: string]: string | undefined | null} = {};
      const sep1 = ',', sep2 = '|', sep3 = ':';
      let filterMap: {[key: string]: string[]} = {}, filterNames: string[], filterValues: string[] = [], filterCriteria: string[] = [];
      let key, ks;
      $.each(formParams, function () {
        ks = this.k.split('.');
        key = ks[ks.length-1].replace('[]', '');
        if (filterMap[key] === undefined) {
          filterMap[key] = [this.v];
        }
        else {
          filterMap[key].push(this.v);
        }
      });
      filterNames = $.map(filterMap, function (_v, k: string) {
        return k;
      });
      if(filterNames.length) {
        filterNames.sort();
        $.map(filterNames, function (k) {
          filterMap[k].sort();
          filterValues = filterValues.concat(filterMap[k]);
          filterCriteria.push([k, filterMap[k].join(sep1)].join(sep3));
        });
        //params.event_filter_groups = filterNames.join(sep1);
        //params.event_filter_criteria = filterValues.join(sep1);
        params.event_filter_combination = filterCriteria.join(sep2);
        //params.event_filter_amount_groups = String(filterNames.length);
      }
      return params;
    }

    private static appendSiuAdds(siu: string, params: EventParams, $elem: JQuery<HTMLElement>) {
      if(siu) {
        switch(siu) {
          case 'lrv':
            params.learning_resource_type = 'Video (LRV)';
            break;
          case 'lra':
            params.learning_resource_type = 'Article (LRA)';
            break;
        }
      }
    }
  }
  
  class ScrollDepthTracker {
    private reportMax = 100;
    private reported = 0;
    private reportStep = 10;

    private scroller:HTMLElement|null = null;
    private maxPos = -1;
    
    private scheduled: any;

    constructor() {
      this.resized = this.resized.bind(this)
      this.scrolled = this.scrolled.bind(this)
      window.addEventListener("resize", this.resized);
      document.addEventListener("scroll", (event) => {
        let sp = this.scrolled();// scheduled
        this.trackHeight(sp, true);
      });
      this.resized();
      window.setTimeout(() => {
        let sp = this.scrolled();
        this.trackHeight(sp, false);
      }, 1000);
    }

    resized():void {
      this.scroller = this.getScrollParent($('.eh-main')[0]);
      this.maxPos = -1;
    }

    scrolled():number {
      if(this.scroller) {
        let pos = this.scroller.scrollTop || 0;
        if(pos > this.maxPos) {
          this.maxPos = pos;
          return ((pos + this.scroller.clientHeight) / this.scroller.scrollHeight) * 100;
        }
      }
      return -1;
    }
   
    private normalizeStep(x:number):number {
      if(this.reportStep > 0) {
        return Math.min((Math.floor(x / this.reportStep) * this.reportStep), this.reportMax);
      }
      return Math.min(x, this.reportMax);
    }

    public trackHeight(h:number, stepping:boolean):void {
      if(this.reported < h) {
        //console.log("trackHeight 1: ", this.reported, h);
        if(stepping && this.reportStep > 0) {
          let t = this.normalizeStep(this.reported + this.reportStep);
          while( t <= h && this.reported < this.reportMax) {
            this.reported = t;
            this.schedule(this.trackSD, this.reported);
            t = this.normalizeStep(t + this.reportStep);
          }
        } else {
          this.reported = this.normalizeStep(h);
          this.schedule(this.trackSD, this.reported);
        }
      }
    }

    private trackSD(h:number):void {
      //console.log("trakSD ", h);
      Tracking.trackScrollDepth(''+h);
    }

    private schedule(f:Function, arg:any):void {
      if(this.scheduled) {
        window.clearTimeout(this.scheduled);
        this.scheduled = null;
      }
      this.scheduled = window.setTimeout ( f, 250, arg);
    }

    private isScrollable = (node: Element) => {
      if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
        return false
      }
      const style = getComputedStyle(node)
      return ['overflow', 'overflow-x', 'overflow-y'].some((propertyName) => {
        const value = style.getPropertyValue(propertyName)
        return value === 'auto' || value === 'scroll'
      })
    }
    
    private getScrollParent = (node: HTMLElement): HTMLElement => {
      let currentParent = node.parentElement
      while (currentParent) {
        if (this.isScrollable(currentParent)) {
          return currentParent
        }
        currentParent = currentParent.parentElement
      }
      return (document.scrollingElement || document.documentElement) as HTMLElement
    }
    
  }

  class TrackingSiteHierarchy {
    
    private static readonly DL_KEY_PREFIX = 'site_h';
    
    private readonly headings: string[];
    
    constructor(private readonly dataLayer: DataLayer) {
      this.headings = [];
      this.parseDataLayer();
  
      Tracking.earlyOnsiteNavigations
        .splice(0, Tracking.earlyOnsiteNavigations.length)
        .forEach((args: OnsiteNavigation) => {
        this.handleOnsiteNavigation.apply(this, args);
      });
    }
    
    public handleOnsiteNavigation(newHeading: string, siblingHeadings: string[] = []) {
      const index = this.headings.findIndex(heading => {
        return siblingHeadings.indexOf(heading) !== -1;
      });
      if (index > -1) {
        this.headings.splice(index);
      }
      this.headings.push(newHeading);
      this.synchronizeDataLayer();
    }
    
    private parseDataLayer() {
      for (let i = 1, key = TrackingSiteHierarchy.DL_KEY_PREFIX + i, heading = this.dataLayer[key];
           heading;
           i++, key = TrackingSiteHierarchy.DL_KEY_PREFIX + i, heading = this.dataLayer[key]) {
        this.headings.push(heading);
      }
    }
    
    private synchronizeDataLayer() {
      for (let i = 1, key = TrackingSiteHierarchy.DL_KEY_PREFIX + i, heading = this.dataLayer[key];
           heading;
           i++, key = TrackingSiteHierarchy.DL_KEY_PREFIX + i, heading = this.dataLayer[key]) {
        if (i > this.headings.length) {
          delete this.dataLayer[key];
        }
      }
      this.headings.forEach((heading, index) => {
        this.dataLayer[`${TrackingSiteHierarchy.DL_KEY_PREFIX}${index + 1}`] = heading;
      });
    }
    
  }
  
  
  const monitorFunctionCall = (func: Function & {'name'?: string}, self: object) => {
    //console.log('monitoring', func, self);
    const functionName = func.name, fnDbg = (val: object) => {
      //noinspection JSUnusedLocalSymbols
      const fnPrintParam = (v: string, k: string) => {
        Tracking.qaLogger.info(k + (k.length >= 8 ? '' : '\t') + (k.length >= 16 ? '' : '\t') + (k.length >= 24 ? '' : '\t') + ' = ' + v);
      };
      if (typeof val === 'object') {
        $.map(val, fnPrintParam);
      }
      return null;
    };
    
    return function () {
      Tracking.qaLogger.info('#_#_#_#_#_#_#_#_#_#_#_#_#');
      $.map(arguments, fnDbg);
      const args = $.makeArray(arguments);
      let formatStr = '#executing %s(';
      args.unshift(functionName);
      for (let i = 0; i < arguments.length; i++) {
        if (i > 0) {
          formatStr += ', ';
        }
        formatStr += '%o';
      }
      args.unshift(formatStr + ') context: %o');
      args.push(self || undefined);
      //console.log.apply(console, args);
      return func.apply(self || undefined, arguments);
    };
  };
  
  const fixUDO = (func: () => void, self: object) => {
    return function () {
      if (typeof arguments[0] === 'object') {
        for (const p in arguments[0]) {
          if (arguments[0].hasOwnProperty(p)) {
            const v = arguments[0][p];
            if (typeof v === 'boolean') {
              arguments[0][p] = v ? '1' : '0';
            }
            else if (typeof v === 'number') {
              arguments[0][p] = '' + v;
            }
          }
        }
      }
      return func.apply(self || undefined, arguments);
    };
  };
}